html, body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background: #FAFAFA;
  font-family: 'Helvetica Neue', arial, sans-serif;
  font-weight: 400;
  color: #444;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Aiming for 45-75 characters per body line 
   * https://baymard.com/blog/line-length-readability
   */
  --main-width: 750px;
  line-height: 1.5;
}

* {
  box-sizing: border-box;
}
